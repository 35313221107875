import type { Theme } from 'theme-ui'

const theme: Theme = {
    fonts: {
        body: 'Computer Modern Serif, serif',
        heading: 'Computer Modern Serif, serif',
        monospace: 'Menlo, monospace',
    },
    colors: {
        text: '#111',
        background: '#fff',
        primary: '#457b9d',
    },
}

export default theme